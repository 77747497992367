//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HelpDisabledRuCountry from "~/components/common/HelpDisabledRuCountry.vue"
export default {
  name: "LanguageCard",

  data: function () {
    return {
      localPrice: "",
      isOpenFooter: false,
      isRepeatRequest: false,
      isLocalPriceLoading: false
    }
  },

  props: {
    language: {
      type: Object,
      default: () => {
        return {}
      }
    },

    currentServiceForRepeat: {
      type: String,
      default: '',
    },

    isWholesale: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
  },

  computed: {

    isAuthUser: function () {
      return Boolean(this.$store.state.user?.user?.id)
    },
    isUseNuxtLink: function () {
      return Boolean(this.typeNumberReceiving === "buy")
    },

    langInfo: function () {
      if (this.typeNumberReceiving === 'rent') {
        return {
          ...this.language,
          ...this.language?.country
        }
      }
      return this.language
    },

    getCurrency: function () {
      return this.$store.getters["currency/currentCurrency"];
    },

    priceValue: function () {
      const currency = Boolean(this.getCurrency === 'rub') ? '₽' : '$';
      const converterPrice = this.$convertCurrency(this.localPrice || this.langInfo.price || this.langInfo.cost);

      if (this.isWholesale) {
        return [
          `${this.$t('servicesList.from')} `,
          converterPrice,
          currency
        ].join("");
      }
      return [
        converterPrice,
        currency
      ].join("");
    },

    buttonLabel: function () {
      if (this.isWholesale) {
        return "INFO"
      }
      if (this.loading) {
        return this.$t('servicesList.buttonWaiting')
      }

      if (this.typeNumberReceiving === 'rent') {
        return this.$t('servicesList.buttonRent')
      }
      return this.$t('servicesList.buttonBuy')
    },
    countLanguages: function () {
      const count = this.langInfo?.count || 0;
      if (count <= 0) {
        return this.$t('servicesList.quantityFew')
      }

      return [
        count,
        this.$t('servicesList.quantity')
      ].join(" ")
    },

    hreflang: function () {
      return this?.$i18n?.locale || this?.$i18n?.defaultLocale || "en";
    },

    isDisabledButtonBuy: function () {
      if (this.typeNumberReceiving === 'rent') {
        return Boolean(this.loading)
      }
      return Boolean(this.loading || !this.langInfo?.button_status)
    },
    messageDisabledCard: function () {
      if (this.typeNumberReceiving === 'rent') {
        return ""
      }
      if (!this.langInfo?.button_status) {
        return this.$t("servicesList.numberTemporarilyUnavailable");
      }
    },

    typeNumberReceiving: function () {
      return this.$store.state?.["languages-list"]?.typeNumberReceiving || ""
    },

    isDisabledRussian: function () {
      return Boolean(
        String(this.langInfo?.country_external_id) === '0' ||
        String(this.langInfo?.country?.external_id) === '0'
      )
    },
  },

  components: {
    RentInformation: () => import("./RentInformation"),
    HelpDisabledRuCountry
  },

  methods: {
    clickLink: function () {
      if (this.loading) {
        return
      }
      this.$cookies.set("selectedCountryId", this.langInfo.country_external_id);
      this.$router.push(this.localePath(`/countries/${this.langInfo.country_slug || this.langInfo.slug}`));
    },

    clickButton: async function (event) {
      if (this.loading) {
        return
      }
      event.stopPropagation();

      if (this.isWholesale) {
        await this.$router.push(this.localePath(`/docs`));
        return
      }
      if (this.typeNumberReceiving === 'rent') {
        if (!this.isAuthUser) {
          await this.$router.push(this.localePath('/authorization'))
          return
        }
        this.isOpenFooter = !this.isOpenFooter;
        return
      }

      this.$emit("onBuyNumber", {
        isNeedRepeat: this.isRepeatRequest && `${this.language.name}-${this.language.service_slug}` === this.currentServiceForRepeat,
        ...this.langInfo
      });
    },

    setLocalPrice: function (localPrice) {
      this.localPrice = localPrice;
    },
    setLocalPriceLoading: function (val) {
      this.isLocalPriceLoading = val;
    },
  }
}
